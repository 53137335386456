import * as React from "react";
import { Box, Typography } from "@mui/material";
import "./Clients.css";
import GoodDayFarm from "../../assets/clients/GoodDayFarm.png";
import GalaxyLabs from "../../assets/clients/GalaxyLabs.png";
import HouseOfCultivar from "../../assets/clients/HouseOfCultivar.png";
import JusticeCannabis from "../../assets/clients/JusticeCannabis.png";
import Codes from "../../assets/clients/Codes.png";

export default function Clients() {
    return (
        <Box className="client-wrapper">
            <Box className="client-row">
                <Typography className="client-label">
                    Trusted by Industry Leaders
                </Typography>
            </Box>
            <Box className="images-row">
                <Box
                    className="good-day-image"
                    component="img"
                    alt="https://gooddayfarmdispensary.com/"
                    src={GoodDayFarm}
                />
                <Box
                    className="galaxy-labs-image"
                    component="img"
                    alt="https://galaxylabscraft.com/"
                    src={GalaxyLabs}
                />
                <Box
                    className="house-of-cultivar-image"
                    component="img"
                    alt="https://houseofcultivar.com/"
                    src={HouseOfCultivar}
                />
                <Box
                    className="justice-cannabis-image"
                    component="img"
                    alt="https://earthshealing.org/"
                    src={JusticeCannabis}
                />
                <Box
                    className="codes-image"
                    component="img"
                    alt="https://codesdispensary.com/"
                    src={Codes}
                />
            </Box>
        </Box>
    )
}